<template>
  <div>
    <!-- 检索 -->
    <el-form :inline="true" :model="form" ref="form">
      <el-form-item class="form-item" label="机构名称" prop="keyword">
        <el-input
          v-model="form.keyword"
          placeholder="请输入机构名称"
          @keyup.enter="onSubmit"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" label="所在地区" prop="str_province">
        <el-cascader
          :options="options"
          :props="{
            checkStrictly: true,
            label: 'name',
            value: 'adcode',
            children: 'districts',
          }"
          v-model="str_province"
          clearable
          class="city-input"
        ></el-cascader>
      </el-form-item>
      <el-form-item class="form-item" label="类型" prop="org_type">
        <el-select v-model="form.org_type" placeholder="全部" clearable>
          <el-option label="幼儿园" value="1"></el-option>
          <el-option label="小学" value="2"></el-option>
          <el-option label="初中" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label="是否启用" prop="str_mark">
        <el-select v-model="form.str_mark" placeholder="全部" clearable>
          <el-option label="启用" value="1"></el-option>
          <el-option label="禁用" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item form-submit">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetForm('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 检索 -->

    <div class="table-content">
      <div class="table-title">
        <div>机构列表</div>
        <el-button
          v-if="type.indexOf('添加') > -1"
          type="primary"
          @click="handPartAdd"
          >添 加</el-button
        >
      </div>

      <!-- 列表 -->
      <el-table
        :data="tableData"
        border
        tooltip-effect="dark"
        style="width: 100%"
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
      >
        <el-table-column
          fixed="left"
          prop="title"
          label="机构名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column label="所在地区" width="250" align="center">
          <template #default="scope">
            <div>{{ scope.row.areaname }}市-{{ scope.row.cityname }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="org_type"
          label="类型"
          width="150"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="info"
          label="机构介绍"
          width="400"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="updated_at"
          label="最近编辑时间"
          width="350"
          align="center"
        >
        </el-table-column>
        <el-table-column label="状态" width="150" align="center">
          <template #default="scope">
            <div @click="handType(scope.row.mark, scope.$index)">
              <el-switch
                v-model="scope.row.mark"
                :active-value="1"
                :inactive-value="2"
              >
              </el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          show-overflow-tooltip
          min-width="200"
          align="center"
        >
          <template #default="scope">
            <el-button
              v-if="type.indexOf('编辑') > -1"
              @click="handleClick(scope.row.id)"
              type="text"
              size="small"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 列表 -->

      <!-- 分页 -->
      <Pagination
        :pageSize="pageSize"
        :currentPage="currentPage"
        :total="total"
        @handSizeChange="handSizeChange"
        @handCurrentChange="handCurrentChange"
      ></Pagination>
      <!-- 分页 -->
    </div>

    <!-- 弹窗 -->
    <el-dialog title="机构信息" v-model="dialogFormVisible" width="500px">
      <div v-loading="load">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
        >
          <el-form-item label="机构名称：" prop="title">
            <el-input
              v-model="ruleForm.title"
              placeholder="请输入机构名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="所在地区" prop="str_province">
            <el-cascader
              :options="options"
              :props="{
                checkStrictly: true,
                label: 'name',
                value: 'adcode',
                children: 'districts',
              }"
              v-model="province"
              placeholder="请选择所在地区"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" prop="address">
            <el-input
              v-model="ruleForm.address"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              placeholder="请输入联系电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="org_type">
            <el-select v-model="ruleForm.org_type" placeholder="请选择类型">
              <el-option label="幼儿园" value="1"></el-option>
              <el-option label="小学" value="2"></el-option>
              <el-option label="初中" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机构介绍：" prop="info">
            <el-input
              type="textarea"
              v-model="ruleForm.info"
              placeholder="请输入机构介绍"
              maxlength="300"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >保 存</el-button
          >
          <el-button @click="onResetForm('ruleForm')">重 置</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 弹窗 -->
  </div>
</template>

<script>
import Pagination from "@/components/pagination/pagination.vue";
import {
  organizationList,
  organizationEdit,
  organizationDetail,
  organizationStart,
  organizationStop,
} from "@/api/apiList/user-api";
import { addressList } from "@/api/apiList/user-api";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      form: {
        keyword: "",
        str_provinceid: "",
        str_cityid: "",
        org_type: "",
        str_mark: "",
        pageSize: 10,
        page: 1,
      },
      ruleForm: {
        id: "",
        title: "",
        provinceid: "",
        cityid: "",
        address: "",
        phone: "",
        org_type: "",
        info: "",
      },
      rules: {
        title: [{ required: true, message: "请输入机构名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        org_type: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
      str_province: [],
      province: [],
      options: [],
      tableData: [],
      loading: true,
      load: true,
      dialogFormVisible: false,
      type: "",
      pageSize: 10,
      currentPage: 1,
      total: 10,
    };
  },
  methods: {
    // 检索
    onSubmit() {
      if (this.str_province) {
        if (
          this.str_province[0] === "110000" ||
          this.str_province[0] === "120000" ||
          this.str_province[0] === "310000" ||
          this.str_province[0] === "500000"
        ) {
          if (this.str_province.length > 2) {
            this.form.str_provinceid = this.str_province[0];
            this.form.str_cityid = this.str_province[2];
          } else {
            this.form.str_provinceid = this.str_province[0];
            this.form.str_cityid = "";
          }
        } else {
          this.form.str_provinceid = this.str_province[0];
          this.form.str_cityid = this.str_province[1];
        }
      } else {
        this.form.str_provinceid = "";
        this.form.str_cityid = "";
      }
      this.loading = true;
      this.handList();
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.str_province = "";
    },
    // 添加弹窗
    handPartAdd() {
      this.dialogFormVisible = true;
      this.load = false;
      this.onResetForm("ruleForm");
    },
    // 编辑弹窗
    handleClick(e) {
      let obj = {
        id: e,
      };
      this.dialogFormVisible = true;
      this.load = true;
      organizationDetail(obj).then((res) => {
        if (res.result === "200") {
          this.ruleForm.id = e;
          this.ruleForm.title = res.data.title;
          this.ruleForm.address = res.data.address;
          this.ruleForm.phone = res.data.phone;
          this.ruleForm.org_type = res.data.org_type;
          this.ruleForm.info = res.data.info;
          if (res.data.cityid) {
            switch (res.data.provinceid) {
              case "110000":
                this.province = new Array(3);
                this.province[0] = res.data.provinceid;
                this.province[1] = "110100";
                this.province[2] = res.data.cityid;
                break;

              case "120000":
                this.province = new Array(3);
                this.province[0] = res.data.provinceid;
                this.province[1] = "120100";
                this.province[2] = res.data.cityid;
                break;

              case "310000":
                this.province = new Array(3);
                this.province[0] = res.data.provinceid;
                this.province[1] = "310100";
                this.province[2] = res.data.cityid;
                break;

              case "500000":
                this.province = new Array(3);
                this.province[0] = res.data.provinceid;
                this.province[1] = "500100";
                this.province[2] = res.data.cityid;
                break;

              default:
                this.province = new Array(2);
                this.province[0] = res.data.provinceid;
                this.province[1] = res.data.cityid;
                break;
            }
          } else {
            this.province = new Array(1);
            this.province[0] = res.data.provinceid;
          }
          this.load = false;
        }
      });
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.province) {
            if (
              this.province[0] === "110000" ||
              this.province[0] === "120000" ||
              this.province[0] === "310000" ||
              this.province[0] === "500000"
            ) {
              if (this.province.length > 2) {
                this.ruleForm.provinceid = this.province[0];
                this.ruleForm.cityid = this.province[2];
              } else {
                this.ruleForm.provinceid = this.province[0];
                this.ruleForm.cityid = "";
              }
            } else {
              this.ruleForm.provinceid = this.province[0];
              this.ruleForm.cityid = this.province[1];
            }
          } else {
            this.ruleForm.provinceid = "";
            this.ruleForm.cityid = "";
          }
          organizationEdit(this.ruleForm).then((res) => {
            if (res.result === 200) {
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.handList();
            } else if (res.result === "200001") {
              this.$message.error("请选择所在地区");
            }
          });
        } else {
          return false;
        }
      });
    },
    // 表单重置
    onResetForm(formName) {
      this.$refs[formName].resetFields();
      this.province = "";
    },
    // 启用/禁用
    handType(e, idx) {
      let obj = {
        id: this.tableData[idx].id,
      };
      if (e === 1) {
        this.$confirm("是否启用该机构?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            organizationStart(obj).then((res) => {
              if (res.result === "200") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loading = true;
                this.handList();
              }
            });
          })
          .catch(() => {
            this.tableData[idx].mark = 2;
          });
      } else {
        this.$confirm("是否禁用该机构?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            organizationStop(obj).then((res) => {
              if (res.result === "200") {
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loading = true;
                this.handList();
              }
            });
          })
          .catch(() => {
            console.log(1);
            this.tableData[idx].mark = 1;
          });
      }
    },
    handSizeChange(e) {
      this.loading = true;
      this.pageSize = e;
      this.form.pageSize = e;
      this.handList();
    },
    handCurrentChange(e) {
      this.loading = true;
      this.currentPage = e;
      this.form.page = e;
      this.handList();
    },
    handList() {
      organizationList(this.form).then((res) => {
        if (res.result === "200") {
          res.data.data.forEach((item) => {
            switch (item.org_type) {
              case 1:
                item.org_type = "幼儿园";
                break;
              case 2:
                item.org_type = "小学";
                break;
              case 3:
                item.org_type = "初中";
                break;
            }
          });
          this.tableData = res.data.data;
          this.total = res.data.total;
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.type = this.$store.state.type;
    addressList().then((res) => {
      if (res.result === "200") {
        for (let index = 0; index < res.data.length; index++) {
          const arr = res.data[index].districts;
          for (let i = 0; i < arr.length; i++) {
            const array = res.data[index].districts[i].districts;
            for (let j = 0; j < array.length; j++) {
              const element = array[j];
              if (element.districts.length === 0) {
                delete element.districts;
              }
            }
          }
        }
        this.options = res.data;
      }
    });
    this.handList();
  },
};
</script>

<style lang="scss">
.form-item {
  .city-input {
    .el-input {
      width: 200px !important;
    }
  }
}
</style>
